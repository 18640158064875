
// Styles
import 'vuetify/src/components/VTextField/VTextField.sass'
import 'vuetify/src/components/VSelect/VSelect.sass'

// Components
import { VDatePicker, VTextField, VMenu, VSelect } from 'vuetify/lib/components'

// Mixins
import ClickOutside from 'vuetify/lib/directives/click-outside'
import labelWithIcon from '@/mixins/inputs/labelWithIcon'
import Dependent from 'vuetify/lib/mixins/dependent'

import mixins from 'vuetify/lib/util/mixins'
import mergeData from 'vuetify/lib/util/mergeData'

export const defaultMenuProps = {
  closeOnClick: false,
  closeOnContentClick: false,
  disableKeys: true,
  openOnClick: false,
  transition: 'scale-transition',
  minWidth: 'auto',
  offsetY: true,
}

export const defaultPickerProps = {
  scrollable: true,
  showAdjacentMonths: true,
  noTitle: true,
  firstDayOfWeek: 1,
}

export default mixins(VTextField, Dependent, labelWithIcon).extend({
  name: 's-date-input',
  directives: {
    ClickOutside,
  },
  props: {
    clearable: Boolean,
    range: Boolean,
    openOnClear: Boolean,
    menuProps: {
      type: [String, Array, Object],
      default: () => defaultMenuProps,
    },
    pickerProps: {
      type: [Object],
    },
    allowedDates: Function,
    max: String,
    min: String,
  },
  data () {
    return {
      menuIsBooted: false,
      isMenuActive: false,
      lazyValue: this.value !== undefined ? this.value : this.multiple ? [] : undefined,
      selectedItems: [],
    }
  },
  computed: {
    classes () {
      return {
        ...VTextField.options.computed.classes.call(this),
        'v-select': true,
        'v-select--is-menu-active': this.isMenuActive,
      }
    },

    computedCounterValue () {
      // var _a;
      //
      // const value = this.multiple ? this.selectedItems : ((_a = this.getText(this.selectedItems[0])) !== null && _a !== void 0 ? _a : '').toString();
      //
      // if (typeof this.counterValue === 'function') {
      //   return this.counterValue(value);
      // }
      //
      return 0
    },

    directives () {
      return this.isFocused ? [
        {
          name: 'click-outside',
          value: {
            handler: this.blur,
            closeConditional: this.closeConditional,
            include: () => this.getOpenDependentElements(),
          },
        }] : undefined
    },

    isDirty () {
      return Array.isArray(this.lazyValue) ? this.lazyValue.length > 0 : !!this.lazyValue
    },

    menuCanShow: () => true,

    $_menuProps () {
      let normalisedProps = typeof this.menuProps === 'string' ? this.menuProps.split(',') : this.menuProps

      if (Array.isArray(normalisedProps)) {
        normalisedProps = normalisedProps.reduce((acc, p) => {
          acc[p.trim()] = true
          return acc
        }, {})
      }

      return {
        ...defaultMenuProps,
        eager: this.eager,
        value: this.menuCanShow && this.isMenuActive,
        nudgeBottom: normalisedProps.offsetY ? 1 : 0,
        ...normalisedProps,
      }
    },
    $_pickerProps () {
      let normalisedProps = typeof this.pickerProps === 'string' ? this.pickerProps.split(',') : this.menuProps

      if (Array.isArray(normalisedProps)) {
        normalisedProps = normalisedProps.reduce((acc, p) => {
          acc[p.trim()] = true
          return acc
        }, {})
      }

      return {
        ...defaultPickerProps,
        allowedDates: this.allowedDates,
        color: this.color,
        disabled: this.isDisabled,
        value: this.lazyValue,
        max: this.max,
        min: this.min,
        range: this.range,
        readonly: this.isReadonly,
        ...normalisedProps,
      }
    },
    pickerData () {
      const scopeId = this.$vnode && this.$vnode.context.$options._scopeId
      const attrs = scopeId ? {
        [scopeId]: true,
      } : {}
      return {
        attrs: {
          ...attrs,
          id: this.computedOwns,
        },
        props: this.$_pickerProps,
        on: {
          input: this.setValue,
        },
      }
    },
    inputValue () {
      if (Array.isArray(this.lazyValue)) {
        const values = this.lazyValue.map(this.valueFormat)
        if (this.lazyValue.length === 2 && this.range) {
          return values.join(' - ')
        }
        return values.join(', ')
      }
      return this.valueFormat(this.lazyValue)
    },
  },
  methods: {
    blur (e) {
      VTextField.options.methods.blur.call(this, e)
      this.isMenuActive = false
      this.isFocused = false
    },
    valueFormat (val) {
      if (!val) return val
      return val.split('-').reverse().join('.')
    },
    activateMenu () {
      if (!this.isInteractive || this.isMenuActive) return
      this.isMenuActive = true
    },
    clearableCallback () {
      this.setValue(this.multiple || this.range ? [] : null)
      this.$nextTick(() => this.$refs.input && this.$refs.input.focus())
      if (this.openOnClear) this.isMenuActive = true
    },

    closeConditional (e) {
      if (!this.isMenuActive) return true
      return !this._isDestroyed && ( // Click originates from outside the menu content
          // Multiple selects don't close when an item is clicked
          !this.getContent() || !this.getContent().contains(e.target)) && // Click originates from outside the element
        this.$el && !this.$el.contains(e.target) && e.target !== this.$el
    },
    filterDuplicates (arr) {
      return [...new Set(arr)]
    },

    getContent () {
      return this.$refs.menu && this.$refs.menu.$refs.content
    },
    genDefaultSlot () {
      return [
        this.genFieldset(),
        this.$createElement('div', {
            staticClass: 'v-select__slot',
            directives: this.directives,
          }, [
            this.genLabel(),
            this.prefix ? this.genAffix('prefix') : null,
            this.genInput(),
            this.suffix ? this.genAffix('suffix') : null,
            this.genClearIcon(),
            this.genIconSlot(),
            this.genHiddenInput(),
          ],
        ),
        this.genMenu(),
        this.genProgress(),
      ]
    },

    genInput () {
      const input = VSelect.options.methods.genInput.call(this)

      input.data = mergeData(input.data, {
        domProps: {
          value: this.inputValue,
        },
      })

      return input
    },

    genHiddenInput () {
      return this.$createElement('input', {
        domProps: {
          value: this.lazyValue,
        },
        attrs: {
          type: 'hidden',
          name: this.attrs$.name,
        },
      })
    },

    genPicker () {
      return this.$createElement(VDatePicker, { ...this.pickerData })
    },

    genMenu () {
      const props = this.$_menuProps
      props.activator = this.$refs['input-slot'] // Attach to root el so that
      // menu covers prepend/append icons

      if ( // TODO: make this a computed property or helper or something
        this.attach === '' || // If used as a boolean prop (<v-menu attach>)
        this.attach === true || // If bound to a boolean (<v-menu :attach="true">)
        this.attach === 'attach' // If bound as boolean prop in pug (v-menu(attach))
      ) {
        props.attach = this.$el
      } else {
        props.attach = this.attach
      }

      return this.$createElement(VMenu, {
        attrs: {
          role: undefined,
        },
        props,
        on: {
          input: val => {
            this.isMenuActive = val
            this.isFocused = val
          },
        },
        ref: 'menu',
      }, [this.genPicker()])
    },

    getMenuIndex () {
      return this.$refs.menu ? this.$refs.menu.listIndex : -1
    },
    onBlur (e) {
      e && this.$emit('blur', e)
    },
    onMouseUp (e) {
      // eslint-disable-next-line sonarjs/no-collapsible-if
      if (this.hasMouseDown && e.which !== 3 && this.isInteractive) {
        // If append inner is present
        // and the target is itself
        // or inside, toggle menu
        if (this.isAppendInner(e.target)) {
          this.$nextTick(() => this.isMenuActive = !this.isMenuActive)
        }
      }

      VTextField.options.methods.onMouseUp.call(this, e)
    },
    onClick (e) {
      if (!this.isInteractive) return

      if (!this.isAppendInner(e.target)) {
        this.isMenuActive = true
      }

      if (!this.isFocused) {
        this.isFocused = true
        this.$emit('focus')
      }

      this.$emit('click', e)
    },
    setSelectedItems () {
      const values = !this.multiple || !Array.isArray(this.internalValue) ? [this.internalValue] : this.internalValue

      this.selectedItems = this.filterDuplicates(values)
    },

    setValue (value) {
      if (this.range && Array.isArray(value)) {
        value = value.sort()
      }
      this.internalValue = value
      this.$emit('change', value)
    },

    isAppendInner (target) {
      // return true if append inner is present
      // and the target is itself or inside
      const appendInner = this.$refs['append-inner']
      return appendInner && (appendInner === target || appendInner.contains(target))
    },
  },
})
