import filterable from 'vuetify/lib/mixins/filterable'

export default function (listData) {
  return filterable.extend({
    props: {
      noDataText: {
        type: String,
        default: 'ST.no_choice',
      },
    },
    computed: {
      listData () {
        const data = listData.call(this)
        data.props = {
          ...(data.props || {}),
          noDataText: this.loading ? this.$t('ST.loading') : (this.$te(this.noDataText)? this.$t(this.noDataText): this.noDataText),
        }
        return data
      },
    },
  })
}
