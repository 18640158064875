import {defaultData} from "./state";

export default {
  SET_TOKEN(state, { token, scopes }) {
    this.$axios.setToken(token || false, 'Bearer')
    state.token = token || null
    state.scopes = scopes
  },
  RESET(state) {
    Object.assign(state, defaultData())
  }
}
