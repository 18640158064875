
import { fetchFactory } from '@/mixins/fetch'
import { filterToQuery } from '@/utils/smarteh/tasks/filter'

export default {
  name: 'tasks-report',
  mixins: [
    fetchFactory('fields', '/tasks/report/fields'),
  ],
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      modal: false,
      data: {
        fields: [],
      },
      loading: false,
      error: null,
      result: null,
    }
  },
  computed: {
    localStorageFields: {
      get () {
        return (localStorage?.getItem('tasks-report_data_fields') || '').split(',').filter(v => !!v)
      },
      set (v) {
        localStorage?.setItem('tasks-report_data_fields', v.join(','))
      },
    },
  },
  methods: {
    fetch () {
      if (this.loading) return
      this.loading = true
      this.error = null
      this.result = null
      return this.$axios.post('tasks/report', {
        ...filterToQuery(this.filter),
        fields: this.data.fields.join(','),
      }).then(({ data }) => this.result = data).catch((e) => {
        this.error = e
      }).finally(() => {
        this.loading = false
      })
    },
    open () {
      this.modal = true
    },
  },
  watch: {
    modal (v) {
      if (v) {
        if (!this.fields.data) {
          this.fetchFields()
          this.data.fields = this.localStorageFields
        }
      } else {
        this.result = null
      }
    },
    'data.fields' (v) {
      this.localStorageFields = v
    },
  },
}
