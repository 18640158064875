import { PER_PAGE } from '@/utils/smarteh/pagination'

export const state = (per_page = PER_PAGE) => ({
  page: 1,
  per_page,
})

export const mutations = (default_per_page = PER_PAGE) => ({
  SET_PAGE (state, page) {
    state.page = page || 1
  },
  SET_PER_PAGE (state, per_page) {
    state.per_page = per_page || default_per_page
  },
})
