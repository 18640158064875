
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  filterToQuery,
  getDefaultFilter,
  getPropertyFilterKey, getPropertyIDFromFilterKey,
  MATERIAL_PARAM_REGEX,
} from '@/utils/smarteh/materials/filter'
import { useComputedStateAndMutation } from '@/mixins/vuex'

export default {
  mixins: [
    useComputedStateAndMutation('showFilter', 'materials.show_filter', 'materials/SET_SHOW_FILTER'),
  ],
  data: () => ({
    filter: getDefaultFilter(),
  }),
  created () {
    this.showFilter = this.$vuetify.breakpoint.mdAndUp
  },
  computed: {
    ...mapState({
      materials: state => state.materials,
    }),
    category_id: {
      get () {
        return (this.filter.category_id || [])[0] || null
      },
      set (category_id) {
        this.filter.category_id = category_id ? [category_id] : []
      },
    },
    width () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return 400
        default:
          return 300
      }
    },
  },
  methods: {
    ...mapActions({
      fetchProperties: 'materials/fetchProperties',
      fetchCategories: 'materials/fetchCategories',
    }),
    ...mapMutations({
      applyFilter: 'materials/SET_FILTER',
    }),
    submitFilter () {
      this.$store.commit('materials/SET_PAGE')
      this.applyFilter(this.filter)
    },
    clearFilter () {
      this.filter = getDefaultFilter()
      this.submitFilter()
    },
    getPropertyValue (property) {
      return this.filter[getPropertyFilterKey(property.property_id)] || []
    },
    setPropertyValue (property, value) {
      if (value.length) {
        this.$set(this.filter, getPropertyFilterKey(property.property_id), value)
      } else {
        this.$delete(this.filter, getPropertyFilterKey(property.property_id))
      }
    },
  },
  watch: {
    'materials.filter': {
      handler () {
        this.filter = { ...getDefaultFilter(), ...this.materials.filter }
      },
      immediate: true,
    },
    'filter.category_id': {
      handler (v, o) {
        if (v === o) return
        this.fetchProperties(this.filter.category_id).then((data) => {
          const filter = { ...this.filter }
          for (const key in filter) {
            const prop_id = getPropertyIDFromFilterKey(key)
            if (!prop_id) continue
            const prop = data.find(p => p.property_id === prop_id)
            if (prop) {
              filter[key] = filter[key].filter(value_id => prop.values.some(v => v.value_id === value_id))
            }
            if (!prop || !filter[key].length) {
              delete filter[key]
            }
          }
          this.filter = filter
        })
      },
      immediate: true,
    },
  },
}
