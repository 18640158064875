import Vue from 'vue'
import { parseArray } from '@/utils/smarteh/filter'

function addDisabled (v) {
  if (
    !this.dontDisabledNoExistItems
    && typeof v === 'object'
    && typeof this.itemDisabled === 'string'
  ) {
    return { ...v, [this.itemDisabled]: true }
  }
  return v
}

export default function (allItems) {
  return Vue.extend({
    props: {
      dontDisabledNoExistItems: Boolean,
    },
    computed: {
      allItems () {
        const items = allItems.call(this)
        if (Array.isArray(this.value)) {
          items.push(...parseArray(this.value).array.map(addDisabled, this))
          return this.filterDuplicates(items)
        } else if (typeof this.value === 'object' && this.value) {
          items.push(items.length ? addDisabled.call(this, this.value) : this.value)
          return this.filterDuplicates(items)
        }
        return items
      },
    },
  })
}
