
  import {cloneDeep} from 'lodash'
  export default {
        data: () => ({
          employee: null,
          show: false
        }),
    methods: {
      close() {
        if (this.$showDetails.employeeData)
          this.$showDetails.employee(null)
      }
    },
    watch: {
      '$showDetails.employeeData': {
        handler(value) {
          if (value) {
            this.employee = cloneDeep(value)
            this.show = true
            this.$fire.analytics.logEvent('employee_details', {
              id: this.employee.employee_id,
              name: `${this.employee.surname} ${this.employee.name}`.trim(),
              company: this.employee.company && this.employee.company.name
            })
          } else {
            this.show = false
          }
        },
        immediate: true,
        deep: true
      },
      show(bool) {
        if (bool)
          this.$blockRouteLeave(this, () => {
            this.close()
            return true;
          })
        else {
          this.close()
          this.$unBlockRouteLeave(this)
        }
      }
    }
  }
