import { get } from 'lodash'
import Vue from 'vue'

export function useComputedStateAndMutation (name, state_path, mutator) {
  return Vue.extend({
    computed: {
      [name]: {
        get () {
          return get(this.$store.state, state_path)
        },
        set (v) {
          this.$store.commit(mutator, v)
        },
      },
    },
  })
}
