export const ORDERS = [
  {
    text: 'Від нових до старих заявок',
    value: 'task_id-desc',
  },
  {
    text: 'Від старих до нових заявок',
    value: 'task_id-asc',
  },
  {
    text: 'Від нових до старих етапів',
    value: 'stage_id-desc',
  },
  {
    text: 'Від старих до нових етапів',
    value: 'stage_id-asc',
  },
]

export const DEFAULT_ORDER = null
