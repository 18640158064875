/**
 *  Додає до массиву даних об'єкт якщо його нема в массиві
 * @param {Object[]} array
 * @param {Object|Object[]} object
 * @param {String} param_id
 * @param {String} disabled_param
 * @return {Object[]}
 */
export function compareDataListAndObject (
  array,
  object,
  param_id,
  disabled_param,
) {
  if (!Array.isArray(array)) {
    array = []
  } else {
    array = array.slice()
  }
  if (!object || Array.isArray(object) && !object.length) return array
  if (!Array.isArray(object)) object = [object]
  for (const item of object) {
    if (array.find(i => i?.[param_id] === item?.[param_id])) continue
    if (disabled_param && array.length) {
      array.unshift({
        ...item,
        [disabled_param]: true,
      })
    } else {
      array.unshift(item)
    }
  }
  return array
}

export function mergeProps (origin, source) {
  if (!origin) return source
  if (!source) return origin
  if (typeof origin !== 'object') {
    return {
      type: origin,
      ...source,
    }
  }
  return {
    ...origin,
    ...source,
  }
}

export {
  mergeListeners, default as mergeData,
} from 'vuetify/lib/util/mergeData'
