
import VTooltip from '@/components/v/tooltip'
import { mergeProps } from '@/utils/mergeData'
import { isMobile } from '@/utils/device/mobile'

export default VTooltip.extend({
  name: 's-tooltip',
  props: {
    openOnClick: mergeProps(VTooltip.options.props.openOnClick, {
      default () {
        return isMobile()
      },
    }),
    openOnHover: mergeProps(VTooltip.options.props.openOnHover, {
      default () {
        return !isMobile()
      },
    }),
  },
})
