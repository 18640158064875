import Dayjs, {duration} from 'dayjs'
import {num2str} from "@/utils/format/string";

export const FORMATS = {
    DATETIME_DISPLAY: 'DD.MM.YYYY HH:mm',
    DATETIME_LOCAL: 'YYYY-MM-DDTHH:mm:ss',
    DATETIME_SAVE: 'YYYY-MM-DDTHH:mm:ssZZ',
    DATE_SAVE: 'YYYY-MM-DD',
    DATE_DISPLAY: 'DD.MM.YYYY',
    TIME_SAVE: 'HH:mm:ss',
    TIME_DISPLAY: 'HH:mm',
}

export function minutesToObject(minutes) {
    const dur = duration(minutes, 'minutes')
    return {
        days: dur.asDays() ^ 0,
        hours: dur.hours(),
        minutes: dur.minutes(),
    }
}

export function formatMinutesToDuration(minutes) {
    const dur = minutesToObject(minutes)
    let text = [];
    if (dur.days)
        text.push(dur.days + ' ' + num2str(dur.days, ['день', 'дня', 'днів']))
    if (dur.hours)
        text.push(dur.hours + ' ' + num2str(dur.hours, ['година', 'години', 'годин']))
    if (dur.minutes)
        text.push(dur.minutes + ' ' + num2str(dur.minutes, ['хвилина', 'хвилини', 'хвилин']))

    return text.join(' ')
}

export const DateTimeMask = {
    mask: '##.##.#### ##:##',
    tokens: {
        '#': {pattern: /\d/}
    }
}
export const DateMask = {
    mask: '##.##.####',
    tokens: {
        '#': {pattern: /\d/}
    }
}
export const TimeMask = {
    mask: '##:##:##',
    tokens: {
        '#': {pattern: /\d/}
    }
}

export function parseDateMask(text) {
    let [day, month, year] = text.replace(/[^\d.]/g, '').split('.')
    let dayjs = new Dayjs()
    if (year) {
        if (year.length === 4) {
            dayjs = dayjs.year(+year)
        } else if (year.length < 4) {
            dayjs = dayjs.year(+(String(dayjs.year()).slice(0, -year.length) + year))
        }
    }
    if (month) {
        if (month > 12) month = 12
        if (month < 1) month = 1
        dayjs = dayjs.month(month - 1)
    }
    if (day) {
        if (day < 1) day = 0
        if (day > dayjs.daysInMonth()) day = dayjs.daysInMonth()
        dayjs = dayjs.date(+day)
    }
    return dayjs
}

export function parseDateMaskToString(text) {
    return parseDateMask(text).format(FORMATS.DATE_SAVE)
}

export function parseTimeMask(text) {
    let [hour, minute, second] = text.replace(/[^\d:]/g, '').split(':')
    let dayjs = new Dayjs()
    if (hour) {
        if (hour > 23) hour = 23
        dayjs = dayjs.hour(+hour)
    }
    if (minute) {
        if (minute > 59) minute = 59
        dayjs = dayjs.minute(+minute)
    }
    if (second) {
        if (second > 59) second = 59
        dayjs = dayjs.second(+second)
    } else {
        dayjs = dayjs.second(0)
    }
    return dayjs
}

export function parseTimeMaskToString(text) {
    return parseTimeMask(text).format(FORMATS.TIME_SAVE)
}

/**
 *  @param {String} text
 */
export function parseDateTimeMask(text) {
    let [date, time] = text.replace(/\s{2,}/g, ' ').split(' ', 2)
    date = parseDateMask(date)
    time = parseTimeMask(time)
    return date.set('hour', time.hour()).set('minute', time.minute()).set('second', time.second())
}

export function parseDateTimeMaskToString(text) {
    return parseTimeMask(text).format(FORMATS.DATE_SAVE + ' ' + FORMATS.TIME_SAVE)
}
