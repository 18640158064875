import { camelCase } from 'lodash'
import Vue from 'vue'

/**
 * @param {String} name
 * @param {String|Function} url
 * @param {Object<method: GET|POST|PUT|PATCH|DELETE, data: Object|prepareData, params: Object|prepareParams>} options
 * @param {Object} extraData
 * @param {Boolean} noReFetch
 * @param {Function} fetchFunction
 */
export function fetchFactory (
  name,
  url,
  options = {},
  extraData = {},
  noReFetch = true,
  fetchFunction = fetch
) {
  const key = name
  const func = camelCase(`fetch_${name}`)
  return Vue.extend({
    data () {
      return {
        [key]: {
          pending: false,
          data: null,
          error: null,
          cancel: null,
          ...(typeof extraData === 'function'
            ? extraData.call(this)
            : extraData),
        },
      }
    },
    beforeDestroy () {
      this[key].cancel && this[key].cancel()
    },
    methods: {
      async [func] (config = {}) {
        if (noReFetch && this[key].pending) return
        if (this[key].cancel) {
          this[key].cancel()
        }
        const urlString = typeof url === 'function'
          ? url.call(this)
          : String(url)
        const params = typeof options.params === 'function' ? options.params.call(this) : (options.params || {})
        const data = typeof options.data === 'function' ? options.data.call(this) : (options.data || {})
        return fetchFunction.call(this, this[key], {
          url: urlString,
          ...options,
          params,
          data,
          ...config,
        })
      },
    },
  })
}

/**
 * @callback prepareData
 * @return {Object}
 */

const pendingRequests = new WeakMap()

/**
 * @callback prepareParams
 * @return {Object}
 */
export function fetch (container, requestConfig) {
  container.pending = true
  container.error = null
  let controller

  if (!requestConfig.signal) {
    controller = new AbortController()
    requestConfig.signal = controller.signal
  }

  const transformResponse = requestConfig.transformResponse || (data => data)
  delete requestConfig.transformResponse

  const pending = this.$axios.request(requestConfig)
  pendingRequests.set(container, pending)
  const isActual = () => pendingRequests.get(container) === pending

  if (controller) {
    container.cancel = pending.cancel = controller.abort.bind(controller)
  }

  pending.then(
    result => {
      if (isActual()) {
        container.data = transformResponse.call(this, result)
      }
    },
  ).finally(
    () => {
      if (isActual()) {
        container.pending = false
        container.cancel = null
      }
    },
  ).catch(
    e => {
      if (!this.$axios.isCancel(e) && isActual()) {
        container.error = e
      }
    },
  )

  return pending
}

export function fetchAllCursor (container, requestConfig) {
  container.pending = true
  container.error = null
  let controller

  if (!requestConfig.signal) {
    controller = new AbortController()
    requestConfig.signal = controller.signal
    container.cancel = controller.abort.bind(controller)
  }
  const transformResponse = requestConfig.transformResponse || (data => data)
  delete requestConfig.transformResponse
  let pending
  const isActual = () => pendingRequests.get(container) === pending
  pending = new Promise(async (r, rj) => {
    let result = []
    try {
      let cursor = ''
      do {
        const response = await this.$axios.request({
          ...requestConfig,
          params: {
            ...(requestConfig.params || {}),
            cursor,
          }
        })
        result = result.concat(transformResponse(response.data))
        cursor = response?.meta?.next_cursor
      } while (cursor)
      container.data = result
    } catch (e) {
      if (!this.$axios.isCancel(e) && isActual()) {
        container.error = e
      }
    } finally {
      if (isActual()) {
        container.pending = false
        container.cancel = null
      }
    }
  })
  pendingRequests.set(container, pending)
  pending.cancel = container.cancel

  return pending
}
