export function objectsListFromClients(clients, search_input, useSort = true) {
  try {
    if (!Array.isArray(clients)) return [];
    if (useSort) {
      clients = clients.slice().sort(this.$sort.companies)
    }
    return clients.reduce((res, client) => {
      if (
        !client.objects
        || !client.objects.length
      ) return res;
      const objects = client.objects.reduce((objects, object) => {
        if (
          !search_input
          || this.$filter.objects(object, search_input)
        ) {
          objects.push(object)
        }
        return objects
      }, [])
      if (objects.length) {
        if (useSort) {
          objects.sort(this.$sort.objects)
        }
        if (res.length) res.push({divider: true})
        res.push({header: client.name}, ...objects)
      }
      return res
    }, [])
  } catch (e) {
    console.error(e)
    return []
  }
}
