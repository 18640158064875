import { render, staticRenderFns } from "./header.vue?vue&type=template&id=6ef2b384"
import script from "./header.vue?vue&type=script&lang=js"
export * from "./header.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SHeaderSearch: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/s/header-search.vue').default,SBtn: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/s/btn.vue').default,NotificationsHeader: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/notifications/header.vue').default})
