export default async function (session, firebase) {
  // Can only be initiated on client side
  if (!process.client) {
    return
  }

  await import('firebase/messaging')

  if (firebase.messaging.isSupported()) {
    const messagingService = session.messaging()

    messagingService.usePublicVapidKey("BLvytkxPB6cMgbTGut916ZAuWUInSg1oAQcCcimsg6JQqcW9P6t-iKChysLzHHF2CdqPxsc6yDu4rz9X-Yy2xmY")

    return messagingService
  }
}
