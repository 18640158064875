export const isSupportCache = () => !!window.caches

export const APP_CACHE_PREG = /^smarteh-(precache|runtime)-/
export const FILES_CACHE_PREG = /^smarteh-files/

export const cacheCategories = {
  app: APP_CACHE_PREG,
  files: FILES_CACHE_PREG,
  other: null,
}

export const calcCache = async () => {
  const cacheByCategory = {}
  for (const key in cacheCategories) {
    cacheByCategory[key] = {
      size: 0,
      count: 0,
    }
  }
  const cacheNames = await caches.keys()
  await Promise.all(cacheNames.map(async (cacheName) => {
    const cache = await caches.open(cacheName)
    const keys = await cache.keys()

    let cacheSize = 0

    await Promise.all(keys.map(async key => {
      const response = await cache.match(key)
      const blob = await response.blob()
      cacheSize += blob.size
    }))
    for (const key in cacheCategories) {
      if (cacheCategories[key] === null || cacheCategories[key]?.test(cacheName)) {
        cacheByCategory[key].size += cacheSize
        cacheByCategory[key].count += keys.length
        return
      }
    }
    console.warn(`Сache "${cacheName}" was not assigned a category. Cache size: ${cacheSize}`)
  }))
  cacheByCategory.total = await navigator?.storage?.estimate()?.then((estimate) => estimate?.usageDetails ? estimate.usageDetails?.caches || 0 : null)
  return cacheByCategory
}
