import Vue from 'vue'
import { computedHeaderItems } from '@/utils/items'

export default function (computedItems) {
  return Vue.extend({
    props: {
      headerGroupBy: [String, Function],
      headerText: [String, Function],
    },
    computed: {
      computedItems () {
        const items = computedItems.call(this)
        if (this.headerText && this.headerGroupBy) {
          return computedHeaderItems(items, this.headerGroupBy, this.headerText)
        }
        return items
      },
    },
  })
}
