import { set } from 'lodash'

export const state = (params = {}) => ({
  loading: false,
  error: null,
  ...params,
})

export const mutations = (prefix_key = '', prefix_path = '') => {
  if (prefix_key) prefix_key = prefix_key + '_'
  if (prefix_path) prefix_path = prefix_path + '.'
  return {
    [`SET_${prefix_key}LOADING`] (state, bool) {
      set(state, `${prefix_path}loading`, !!bool)
    },
    [`SET_${prefix_key}ERROR`] (state, error) {
      set(state, `${prefix_path}error`, error || null)
    },
  }
}
