export default ({ app: { $sentry }, store }) => {
  store.watch(
    state => state.profile.data,
    (v) => {
      if (v) {
        $sentry.setUser({
          id: v.employee_id,
          username: `${v.surname} ${v.name}`.trim(),
        })
      }
    }, {
      immediate: true
    }
  )
}
