import {
  filterParametersFilter as baseFilterParametersFilter,
  filterToQuery as baseFilterToQuery,
  filterFromQuery as baseFilterFromQuery,
  getDefaultFilterByMap,
} from '@/utils/smarteh/filter'

const MaterialsFilterMap = {
  search: String,
  material_id: Number,
  category_id: Number,
  archived: Boolean,
  has_on_warehouses: Boolean,
}

export const MATERIAL_PARAM_KEY_PREFIX = 'p_'
export const MATERIAL_PARAM_REGEX = /^p_(\d+)$/

export function getPropertyFilterKey (param) {
  return MATERIAL_PARAM_KEY_PREFIX + param
}

export function getPropertyIDFromFilterKey (prop) {
  prop = prop.match(MATERIAL_PARAM_REGEX)
  if (!prop) return null
  return +prop[1]
}

export function filterParametersFilter (filter) {
  const result = baseFilterParametersFilter(filter, MaterialsFilterMap)
  for (const key in filter) {
    if (!MATERIAL_PARAM_REGEX.test(key)) continue
    if (!Array.isArray(filter[key]) || !filter[key].length) continue
    result[key] = filter[key]
  }
  return result
}

export function getDefaultFilter () {
  return {
    ...getDefaultFilterByMap(MaterialsFilterMap),
    has_on_warehouses: true,
  }
}

function filterToQuery (filter) {
  filter = {...filter}
  const query = baseFilterToQuery(filter, MaterialsFilterMap)
  for (const key in filter) {
    if (!MATERIAL_PARAM_REGEX.test(key)) continue
    if (!Array.isArray(filter[key]) || !filter[key].length) continue
    query[key] = filter[key].join(',')
  }
  return query
}

export function filterToClientQuery (filter) {
  filter = {...filter}
  if (filter.has_on_warehouses === true) {
    delete filter.has_on_warehouses
  }
  const query = filterToQuery(filter)
  return query
}

export function filterToServerQuery (filter) {
  filter = {...filter}
  if (filter.has_on_warehouses === false) {
    delete filter.has_on_warehouses
  }
  const query = filterToQuery(filter)
  return query
}

export function filterFromClientQuery (query) {
  const filter = baseFilterFromQuery(query, MaterialsFilterMap)
  if (filter.has_on_warehouses !== false) {
    filter.has_on_warehouses = true
  }
  for (const key in filter) {
    if (!MATERIAL_PARAM_REGEX.test(key)) continue
    if (!Array.isArray(filter[key]) || !filter[key].length) continue
    query[key] = filter[key].join(',')
  }
  return filter
}
