import Vue from 'vue'

export default function (getText) {
  return Vue.extend({
    props: {
      itemTextI18n: Boolean,
    },
    methods: {
      getText (item) {
        const text = getText.call(this, item)
        if (this.itemTextI18n && typeof text === 'string') {
          return this.$t(text)
        }
        return text
      },
    },
  })
}
