import { num2str } from '@/utils/format/string'

export function getLabel (source, count) {
  if (count === undefined) {
    return source
  }
  if (Array.isArray(source)) {
    return num2str(count, source)
  } else if (!this.$te(source)) {
    return source
  } else {
    return this.$tc(source, count)
  }
}
