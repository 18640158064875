import Vue from 'vue'

const components = new Map()

/**
 * Registration component
 * @param component Vue Component (like `this`)
 * @param {callback} callback Callback function where your logic. If you dont need route leave, function need return true.
 */
const blockRouteLeave = (component, callback) => {
  components.set(component, callback)
  component.$once('hook:beforeDestroy', () => {
    components.delete(component)
  })
}

/**
 * This callback is displayed as a global member.
 * @callback callback
 * @param {Route} to
 * @param {Route} from
 * @return {boolean} If `true` route will block
 */

/**
 * Unregister component
 * @param component Vue Component (like `this`)
 */
const unBlockRouteLeave = (component) => {
  components.delete(component)
}

export default function ({ app }) {
  // this.$blockRouteLeave(this, () => { ... })
  Vue.prototype.$blockRouteLeave = blockRouteLeave

  // this.$unBlockRouteLeave(this)
  Vue.prototype.$unBlockRouteLeave = unBlockRouteLeave

  let isBack = false
  window.addEventListener('popstate', () => {
    isBack = true
  })

  app.router.beforeEach((to, from, next) => {
    if (!isBack) {
      next()
      return
    }
    isBack = false
    const list = [...components.entries()]

    for (let i = list.length - 1; i >= 0; i--) {
      if (
        list[i][1] === undefined
        || typeof list[i][1] === 'function'
        && !!list[i][1].call({ to, from })
      ) {
        list.splice(i + 1).forEach(i => components.delete(i[0]))
        next(false)
        return
      }
    }

    next()
  })
}
