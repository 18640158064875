import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDk4STi5QxT3Hjy9VyHGpvepOw8d8g-aVA',
    libraries: 'places',
    region: 'UA',
    language: 'uk'
  }
})
