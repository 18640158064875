import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0fdac5c2"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=0fdac5c2&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SInternetStatus: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/s/internet-status.vue').default,SNavigation: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/s/navigation.vue').default,SHeader: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/s/header.vue').default,SBar: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/s/bar.vue').default,TasksFilter: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/tasks/filter.vue').default,MaterialsFilter: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/materials/filter.vue').default,SProfile: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/s/profile.vue').default,EmployeeDetails: require('/home/runner/work/Smarteh-Client/Smarteh-Client/components/employee/details.vue').default})
