
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  head () {
    return {
      title: this.$te(`PG.name.${this.$route.path}`) ? this.$t(`PG.name.${this.$route.path}`) : undefined,
      ...this.$nuxtI18nHead(),
    }
  },
  provide () {
    return {
      layout: this,
    }
  },
  computed: {
    ...mapState({
      show_breadcrumbs: state => state.app.show_breadcrumbs,
      show_profile: state => state.app.show_profile
    }),
    ...mapGetters({
      employee_id: 'employee_id',
    }),
  },
  created () {
    this.fetchProfile()
  },
  beforeDestroy () {
    this.socketDisconnect()
  },
  data: () => ({
    show_bar: null,
    employeeSocket: null,
  }),
  methods: {
    ...mapActions({
      fetchProfile: 'profile/fetch'
    }),
    socketConnect () {
      if (this.employeeSocket || !this.employee_id) return
      this.employeeSocket = this.$socket(`/employee/${this.employee_id}`)
    },
    socketDisconnect () {
      this.employeeSocket && this.employeeSocket.close()
      this.employeeSocket = null
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (this.$showDetails.participantData || this.$showDetails.employeeData) {
      this.$showDetails.employee(null)
      this.$showDetails.participant(null)
      next(false)
    } else {
      next()
    }
  },
  watch: {
    show_breadcrumbs: {
      handler (boolean) {
        this.$timeout(300).then(() => {
          this.show_bar = boolean
        })
      },
      immediate: true
    },
    employee_id: {
      handler (v) {
        if (v) {
          this.socketConnect()
        } else {
          this.socketDisconnect()
        }
      },
      immediate: true
    }
  }
}
