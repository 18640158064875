export default {
  async addFavorite (_, { task_id, comment = null }) {
    await this.$axios.post(`tasks/${task_id}/favorite`, {
      comment,
    })
    this.$fire.analytics.logEvent('task_favorite', {
      task_id: task_id,
      favorite: true,
    })
  },
  async removeFavorite (_, { task_id }) {
    await this.$axios.delete(`tasks/${task_id}/favorite`)
    this.$fire.analytics.logEvent('task_favorite', {
      task_id: task_id,
      favorite: false,
    })
  },
}
