
import { getStatusData } from '@/utils/smarteh/reports/status'
import { fileSizeToString } from '@/utils/format/size'

const INTERVAL = 5000

export default {
  name: 'report-status',
  inject: ['layout'],
  props: {
    report: Object,
  },
  data: () => ({
    data: null,
    intervalNumber: null,
    isSubscribe: false,
  }),
  beforeDestroy () {
    this.unsubscribeEvents()
    this.data = null
    this.interval()
  },
  computed: {
    statusData () {
      return getStatusData(this.data?.status)
    },
    fileSize () {
      return this.data?.file?.size && fileSizeToString(this.data.file.size)
    },
    isPending () {
      return !['DONE', 'FAILED', 'CANCELED'].includes(this.data?.status)
    }
  },
  methods: {
    async fetchReport () {
      try {
        this.data = await this.$axios.get(`reports/${this.data.report_uuid}`).then(({ data }) => data)
      } finally {
        if (this.isPending) {
          this.intervalNumber = setTimeout(this.fetchReport, INTERVAL)
        }
      }
    },
    onReportChange (data) {
      if (this.data && this.data.report_uuid === data.report_uuid && this.data.updated_at < data.updated_at) {
        this.data = data
      }
    },
    subscribeEvents () {
      this.layout.employeeSocket?.on('report:change', this.onReportChange)
      this.layout.employeeSocket?.on('connect', this.onConnect)
      this.layout.employeeSocket?.on('disconnect', this.onDisconnect)
    },
    unsubscribeEvents (s = this.layout.employeeSocket) {
      s?.off('report:change', this.onReportChange)
      s?.on('connect', this.onConnect)
      s?.on('disconnect', this.onDisconnect)
    },
    onConnect () {
      this.isSubscribe = true
    },
    onDisconnect () {
      this.isSubscribe = false
    },
    interval () {
      if (this.intervalNumber && (!this.data || !this.isPending)) {
        clearTimeout(this.intervalNumber)
        this.intervalNumber = null
      } else if (this.data && !this.intervalNumber && this.isPending) {
        this.intervalNumber = setTimeout(this.fetchReport, INTERVAL)
      }
    },
  },
  watch: {
    report (r) {
      this.data = r || null
    },
    data: 'interval',

    'layout.employeeSocket': {
      handler (v, ov) {
        if (ov) {
          this.unsubscribeEvents(ov)
        }
        if (v) {
          this.subscribeEvents()
          this.isSubscribe = v.connected
        }
      },
      immediate: true,
    },
  },
}
