export default {
  hasFilter: state => {
    return Object.keys(state.filter || {}) > 0;
  },
  materials: state => ({
    list: state.list,
    loading: state.loading.materials,
    error: state.error.materials,
  }),
}
