
import VAlert from 'vuetify/lib/components/VAlert'
import {Warning} from "@/utils/error";

export default {
  name: 's-error',
  props: {
    border: VAlert.options.props.border,
    text: {
      type: Boolean,
      default: true
    },
    rounded: Boolean,
    noDismissible: Boolean,
    value: {
      validate(v) {
        return v instanceof Error
      }
    },
    message: String,
    warningTimeout: {
      type: Number,
      default: 5000,
    },
    errorTimeout: {
      type: Number,
    },
    type: String,
    noPreLine: Boolean,
    show: {
      default: null,
    },
  },
  data: () => ({
    isActive: false,
    closeTimeout: null
  }),
  computed: {
    internalError () {
      return this.value || this.message && new Error(this.message) || null
    },
    errorType () {
      if (this.type) return this.type
      if (!this.internalError) return 'error'
      if (this.internalError instanceof Warning) {
        return 'warning'
      }
      return 'error'
    },
    internalShow () {
      if (typeof this.show === 'boolean') return this.show
      return !!this.internalError
    },
  },
  methods: {
    close() {
      if (this.value) {
        this.$emit('input', null)
      }
      if (this.message) {
        this.$emit('update:message', null)
      }
    },
  },
  watch: {
    internalError: {
      handler() {
        if (this.closeTimeout) {
          this.closeTimeout.cancel()
          this.closeTimeout = null
        }
        if (!this.noDismissible) return
        if (this.internalError) {
          const timeout = this[`${this.errorType}Timeout`]
          if (timeout) {
            this.closeTimeout = this.$timeout(timeout)
            this.closeTimeout.then(this.close)
          }
        }
      },
      immediate: true
    }
  }
}
