import { DEFAULT_PER_PAGE } from '@/utils/smarteh/tasks/pagination'
import { DEFAULT_ORDER } from '@/utils/smarteh/tasks/order'
import { state as paginationState } from '@/utils/store/pagination'
import { state as metaState } from '@/utils/store/meta'
import { state as orderingState } from '@/utils/store/ordering'
import { state as fetchState } from '@/utils/store/fetch'
import { state as timestampState } from '@/utils/store/timestamp'
import { state as filterState } from '@/utils/store/filter'
import { state as filterOptionsState } from '@/utils/store/filterOptions'

export const defaultData = () => {
  return {
    list: null,

    show_filter: null,

    ...fetchState({ loading: true }),
    ...filterState(),
    ...filterOptionsState(),
    ...timestampState(),
    ...metaState(),
    ...paginationState(DEFAULT_PER_PAGE),
    ...orderingState(DEFAULT_ORDER),

    state: {
      loading: true,
      error: null,
      data: null,
    },
  }
}

export default () => defaultData()
