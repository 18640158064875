
  export default {
    props: ['error'],
    created() {
      console.log('ErrorPage', this.error)
    },
    computed: {
      text() {
        if (this.error)
          switch (this.error.statusCode) {
            case 404:
              return 'Сторінка не знайдена'
          }
        return 'Виникла помилка'
      }
    }
  }
