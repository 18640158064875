import {isMobile} from "@/utils/device/mobile";

export default {
  hasPermission: state => permission => {
    try {
        return state.data['$permissions'][permission] || false
    } catch (e) {
      return false
    }
  },
  dateTimePickerVersion: state => {
    try {
      return state.config.datetime_picker || (isMobile() ? 1 : 2)
    } catch (e) {
      return 1
    }
  },
  employee_id: state => {
    return state.data && state.data.employee_id
  },
  company_id: state => {
    return state.data && state.data.company && state.data.company.company_id
  },
}


