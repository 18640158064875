import {Manager} from "socket.io-client";

export default ({$axios, store}, inject) => {
  const manager = new Manager(process.env.SOCKET_URI, {
    path: '/',
    autoConnect: false,
  })
  inject('socketManager', manager)
  const onAuth = (cb) => {
    cb({
      token: store.state.auth.token,
    })
  }
  const sockets = new Map()

  store.watch(
    state => state.auth.token,
    (new_val, old_val) => {
      if (new_val !== old_val && ~manager._readyState.indexOf("open")) {
        sockets.forEach((count, socket) => {
          socket.disconnect()
          sockets.delete(socket)
        })
        manager.disconnect()
        manager.nsps = {}
      }
      if (new_val) {
        manager.connect()
      }
    },
    {
      immediate: true
    }
  )

  inject('socket', function (nsp, options = {}) {
    const socket = manager.socket(nsp, {
      ...options,
      auth: onAuth,
    })
    if (!socket.connected) socket.connect()
    if (!sockets.has(socket)) {
      sockets.set(socket, 1)
      socket.data = {}
      socket.close = function () {
        let connects = sockets.get(socket)
        sockets.set(socket, --connects)
        if (connects < 1) {
          console.debug('disconnect', socket.nsp, socket)
          socket.disconnect.apply(socket)
          socket.removeAllListeners()
          delete manager.nsps[socket.nsp]
        }
      }
      socket.onAny((...args) => console.debug(socket.nsp, ...args))
      socket.on('connect', () => {
        console.debug(socket.nsp, 'connect')
        $axios.defaults.headers['X-Socket-Ids'] = [...sockets.keys()].filter(
          s => s.connected).map(s => s.id).join(',')
      })
      socket.on('disconnect', (reason) => {
        console.debug(socket.nsp, 'disconnect', reason)
        $axios.defaults.headers['X-Socket-Ids'] = [...sockets.keys()].filter(
          s => s.connected).map(s => s.id).join(',')
      })
      socket.on('connect_error', (error) => {
        console.error('connect_error', error)
        if (error.data && error.data.code === 'invalid_jwt') {
          socketState.data = null
          setTimeout(() => {
            if (sockets.get(socket) > 0)
              socket.connect()
          }, 2000)
        }
      });
    } else {
      const count = sockets.get(socket)
      if(count < 1 && !socket.connected) {
        socket.connect()
      }
      sockets.set(socket, count + 1)
    }
    if (options.teardown !== false) {
      if (this && this.$once) {
        this.$once('hook:beforeDestroy', () => {
          socket.close()
        })
      }
    }
    return socket
  })
}
