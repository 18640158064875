import Vue from 'vue'

export const state = Vue.observable({
  show: false,
  files: [],
  index: 0,
  multiple: false
});

export const close = () => {
  state.show = false
}

export const FilePreview = class {
  constructor ({
    name,
    description,
    src,
    download,
    author,
    type,
    created_at,
    onDelete,
  } = {}) {
    this.name = name
    this.description = description
    this.src = src
    this.download = download
    this.author = author
    this.type = type
    this.created_at = created_at
    this.onDelete = onDelete
  }
}

export const show = (file) => {
  if(!(file instanceof FilePreview)) new Error(`file need object FilePreview`);
  state.files = [file]
  state.multiple = false
  state.index = 0
  state.show = true
}

export const showGroup = (files, index) => {
  files = files.filter(f => f instanceof FilePreview)
  if(!files.length) return;
  state.files = files
  state.multiple = true
  state.index = index || 0
  state.show = true
}

export const select = (index) => {
  if(state.multiple)
    state.index = index
}

export default async () => {
  Vue.$preview = Vue.prototype.$preview = {
    show,
    close,
    showGroup,
    select
  };
}
