
export default {
  name: 's-bar',
  computed: {
    items () {
      const items = [
        {
          to: '/',
          text: this.$t('PG.name./')
        }
      ]
      items.push(...this.$store.state.app.breadcrumbs.map(b => ({...b, text: b.text || this.$t(`PG.name.${b.to}`)})))

      return items
    }
  },
}
