export default {
  SET_EMPLOYEE(state, employee) {
    state.employee = employee || null
  },
  SET_PARTICIPANT(state, participant) {
    state.participant = participant || null
  },
  SET_STAGE(state, stage) {
    state.stage = stage || null
  }
}
