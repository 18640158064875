
import { VSelect } from 'vuetify/lib/components'
import itemsWithValue from '@/mixins/inputs/itemsWithValue'
import labelWithIcon from '@/mixins/inputs/labelWithIcon'
import headersGroupBy from '@/mixins/inputs/headersGroupBy'
import listItemWithArchived from '@/mixins/inputs/listItemWithArchived'
import noDataTextLoading from '@/mixins/inputs/noDataTextLoading'
import itemTextI18n from '@/mixins/inputs/itemTextI18n'
import nullItem from '@/mixins/inputs/nullItem'
import notFilter from '@/mixins/inputs/notFilter'

const nullItemMixin = nullItem(VSelect.options.computed.allItems)
const itemsWithValueMixin = itemsWithValue(nullItemMixin.options.computed.allItems)
const listItemWithArchivedMixin = listItemWithArchived(VSelect.options.computed.listData)
const noDataTextLoadingMixin = noDataTextLoading(listItemWithArchivedMixin.options.computed.listData)
const notFilterMixin = notFilter(VSelect.options.methods.genListWithSlot)
const headersGroupByMixin = headersGroupBy(VSelect.options.computed.computedItems)
const itemTextI18nMixin = itemTextI18n(VSelect.options.methods.getText)

export default VSelect.extend({
  name: 's-select',
  mixins: [
    nullItemMixin,
    itemsWithValueMixin,
    labelWithIcon,
    listItemWithArchivedMixin,
    noDataTextLoadingMixin,
    notFilterMixin,
    headersGroupByMixin,
    itemTextI18nMixin,
  ],
})
