import { get, groupBy } from 'lodash'

export function computedHeaderItems (list, group_by, header) {
  list = groupBy(list, group_by)
  const result = []
  for (const key in list) {
    result.push({
      header: typeof header === 'function'
        ? header(list[key][0])
        : get(list[key][0], header),
    }, ...list[key])
  }
  return result
}
