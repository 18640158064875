export function isMaterialLink (text) {
  text = String(text)
  let matches
  if (matches = text.match(/^SM(\d+)$/)) {
    return +matches[1]
  } else if (
    (matches = text.match(
      /^(?:https?:\/\/)?(?:beta\.)?(?:worker\.)?smarteh\.com\.ua\/(?:#\/)?materials\/(\d+)$/))
    || (matches = text.match(
      /^(?:https?:\/\/)?l(?:ink)?\.smarteh\.com\.ua\/m\/(\d+)$/))
  ) {
    return +matches[1]
  }
  return false
}

export function isTaskLink (text) {
  text = String(text)
  let matches
  if (
    (matches = text.match(
      /^(?:https?:\/\/)?(?:beta\.)?(?:worker\.)?smarteh\.com\.ua\/(?:#\/)?tasks\/(\d+)$/))
    || (matches = text.match(
      /^(?:https?:\/\/)?l(?:ink)?\.smarteh\.com\.ua\/t\/(\d+)$/))
  ) {
    return +matches[1]
  }
  return false
}

export function isLocalLink (text) {
  text = String(text)
  if (text.startsWith(location.origin)) {
    const url = new URL(text)
    return url.pathname + url.search
  }
  return false
}

export function isWarehouseLink (text) {
  text = String(text)
  let matches
  if (
    (matches = text.match(
      /^(?:https?:\/\/)?(?:beta\.)?(?:worker\.)?smarteh\.com\.ua\/(?:#\/)?warehouses\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/i))
    || (matches = text.match(
      /^(?:https?:\/\/)?l(?:ink)?\.smarteh\.com\.ua\/w\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/i))
  ) {
    return +matches[1]
  }
  return false
}
