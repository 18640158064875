import { defaultData } from './state'
import Vue from 'vue'

import { DEFAULT_PER_PAGE } from '@/utils/smarteh/tasks/pagination'
import { DEFAULT_ORDER } from '@/utils/smarteh/tasks/order'
import { filterParametersFilter } from '@/utils/smarteh/tasks/filter'
import { mutations as paginationMutations } from '@/utils/store/pagination'
import { mutations as metaMutations } from '@/utils/store/meta'
import { mutations as orderingMutations } from '@/utils/store/ordering'
import { mutations as fetchMutations } from '@/utils/store/fetch'
import { mutations as timestampMutations } from '@/utils/store/timestamp'
import { mutations as filterMutations } from '@/utils/store/filter'
import {
  mutations as filterOptionsMutations,
} from '@/utils/store/filterOptions'

const filterMixin = filterMutations(filterParametersFilter)
export default {
  SET_SHOW_FILTER (state, bool) {
    state.show_filter = !!bool
  },
  RESET (state) {
    state = Object.assign({}, state, defaultData())
  },

  SET_TASKS (state, tasks) {
    state.list = tasks
  },
  UPDATE_TASK (state, task) {
    const index = state.list.findIndex(t => t.task_id === task.task_id)
    if (index !== -1)
      Vue.set(state.list, index, task)
  },
  ...fetchMutations(),
  ...timestampMutations(),
  ...filterMixin,
  ...metaMutations(),
  ...paginationMutations(DEFAULT_PER_PAGE),
  ...orderingMutations(DEFAULT_ORDER),
  ...filterOptionsMutations(),

  SET_STATE (state, stat) {
    state.state = stat || {}
  },

  SET_FILTER (state, filter = {}) {
    filterMixin.SET_FILTER.call(this, state, filter)
    state.page = 0
  },

  SET_FILTER_ITEM (state, items = {}) {
    state.filter = filterParametersFilter({ ...state.filter, ...items })
    state.page = 0
  },
}
