/**
 * Правильне закінчення слів в множині
 * @param {Number} number
 * @param {String[]} titles
 * @returns {*}
 */
export function num2str(number, titles) {
    if (titles.length === 2) {
        titles = [...titles, titles[1]]
    }
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]];
}
