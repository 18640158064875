
export const defaultData = () => {
  return {
    data: null,
    config: {
      subscribe_notifications: false,
      type_notifications: {},
      datetime_picker: null,
      name_format: 'surname_name',
      tasks_with_comments: true,
      tasks_with_workers: true,
    },
    theme: {
      mode: 'system',
      day_start: '06:00',
      day_end: '20:00'
    },
    notebook: '',
    task_stages_reverse: false,
    task_stages_working_only: false,
  }
};

export default () => defaultData()
