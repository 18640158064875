
import { fetchFactory } from '@/mixins/fetch'
import { filterParametersFilter } from '@/utils/smarteh/tasks/filter'
import { sortByString } from '@/utils/sort'

export default {
  name: 'tasks-personal-filters',
  mixins: [
    fetchFactory(
      'filters',
      '/profile/filters',
      {
        params: {
          type: 'task',
        },
        transformResponse: (data) => data.sort(sortByString(v => v.name))
      },
    )
  ],
  inject: [
    'filter',
  ],
  data: () => ({
    new_filter_name: null,
    saving: false,
    error: null,
    deleting: null,
  }),
  mounted () {
    this.fetchFilters()
  },
  methods: {
    async saveFilter () {
      if (this.saving || !this.$refs.form.validate()) return
      this.error = null
      this.saving = true
      try {
        const data = {
          name: this.new_filter_name.trim(),
          type: 'task',
          params: {
            ...filterParametersFilter(this.filter.filter),
            search_fields: this.filter.filter.search ? this.filter.filter.search_fields : undefined,
          },
        }
        const response = await this.$axios.post('/profile/filters', data)
        this.new_filter_name = null
        this.$nextTick(() => {
          this.fetchFilters()
          this.$refs.form?.resetValidation()
        })
        this.$fire.analytics.logEvent('user_filter_save', {
          ...data.params,
          ...data,
          id: response?.filter_id,
          params: undefined,
        })
      } catch (e) {
        this.error = e
      } finally {
        this.saving = false
      }
    },
    async deleteFilter ({ filter_id, name }) {
      if (this.deleting) return
      this.deleting = filter_id
      this.error = null
      try {
        await this.$axios.delete(`/profile/filters/${filter_id}`)
        this.$fire.analytics.logEvent('user_filter_delete', {
          id: filter_id,
          name,
        })
        this.$nextTick(() => this.fetchFilters())
      } catch (e) {
        this.error = e
      } finally {
        this.deleting = null
      }
    },
    selectFilter ({ filter_id, name, params }) {
      this.filter.applyFilter(params)
      this.filter.preload()
      this.$nextTick(() => {
        this.$refs.select.lazyValue = null
        this.$refs.select.blur()
      })
      this.$fire.analytics.logEvent('user_filter_delete', {
        id: filter_id,
        name,
      })
    },
  }
}
