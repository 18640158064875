export const stages = [
  {
    key: 'CREATED',
    name: 'ET.task_stage.type.CREATED',
    icon: '$task_stage_created',
    color: 'brown',
  },
  {
    key: 'WORKING',
    name: 'ET.task_stage.type.WORKING',
    icon: '$task_stage_working',
    color: 'green',
  },
  {
    key: 'REVISING',
    name: 'ET.task_stage.type.REVISING',
    icon: '$task_stage_revising',
    color: 'light-blue',
  },
  {
    key: 'EMPLOYEE_SEARCHING',
    name: 'ET.task_stage.type.EMPLOYEE_SEARCHING',
    icon: '$task_stage_employee_searching',
    color: 'teal',
  },
  {
    key: 'CONFIRMING',
    name: 'ET.task_stage.type.CONFIRMING',
    icon: '$task_stage_confirming',
    color: 'brown',
  },
  {
    key: 'SUPPLYING',
    name: 'ET.task_stage.type.SUPPLYING',
    icon: '$task_stage_supplying',
    color: 'amber',
  },
  {
    key: 'CANCELED',
    name: 'ET.task_stage.type.CANCELED',
    icon: '$task_stage_canceled',
    color: 'blue-grey',
  },
  {
    key: 'DONE',
    name: 'ET.task_stage.type.DONE',
    icon: '$task_stage_done',
    color: 'pink',
  },
  {
    key: 'CLOSED',
    name: 'ET.task_stage.type.CLOSED',
    icon: '$task_stage_closed',
    color: 'indigo',
  },
]

export const stagesByKey = stages.reduce(
  (obj, status) => ({ ...obj, [status.key]: status }), {},
)

export function getStageData (status) {
  return stagesByKey[status] || null
}

export const statuses = [
  {
    key: 'null',
    name: 'ET.task_stage.status.null',
    icon: '$task_stage_status',
  },
  {
    key: 'DOING',
    name: 'ET.task_stage.status.DOING',
    icon: '$task_stage_status_doing',
  },
  {
    key: 'WAITING',
    name: 'ET.task_stage.status.WAITING',
    icon: '$task_stage_status_waiting',
  },
  {
    key: 'DONE',
    name: 'ET.task_stage.status.DONE',
    icon: '$task_stage_status_done',
  },
  {
    key: 'FAIL',
    name: 'ET.task_stage.status.FAIL',
    icon: '$task_stage_status_fail',
  },
  {
    key: 'CANCELED',
    name: 'ET.task_stage.status.CANCELED',
    icon: '$task_stage_status_cancel',
  },
]

export const statusesByKey = statuses.reduce(
  (obj, status) => ({ ...obj, [status.key]: status }), {},
)

export function getStageStatusData (status) {
  return statusesByKey[status] || null
}
