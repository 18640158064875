import { defaultData } from './state'
import { mutations as metaMutations } from '@/utils/store/meta'
import { mutations as paginationMutations } from '@/utils/store/pagination'
import { mutations as fetchMutations } from '@/utils/store/fetch'
import { mutations as filterMutations } from '@/utils/store/filter'
import {
  mutations as filterOptionsMutations,
} from '@/utils/store/filterOptions'
import { filterParametersFilter } from '@/utils/smarteh/materials/filter'

export default {
  ...fetchMutations(),
  ...metaMutations(),
  ...paginationMutations(),
  ...filterMutations(filterParametersFilter),
  ...filterOptionsMutations(),
  SET_SHOW_FILTER (state, bool) {
    state.show_filter = !!bool
  },
  RESET (state) {
    state = Object.assign({}, state, defaultData())
  },
  SET_MATERIALS (state, materials = []) {
    state.list = materials
  },

  SET_FILTER_CATEGORIES (state, categories = []) {
    state.filter_categories = categories
  },
  SET_FILTER_PARAMETERS (state, parameters = []) {
    state.filter_parameters = parameters
  },
}
