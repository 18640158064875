
import SAutocomplete from '@/components/s/autocomplete'
import { mergeProps } from '@/utils/mergeData'

export default SAutocomplete.extend({
  name: 'group-autocomplete',
  props: {
    itemText: mergeProps(SAutocomplete.options.props.itemText, {
      default: 'name',
    }),
    itemValue: mergeProps(SAutocomplete.options.props.itemValue, {
      default: 'group_id',
    }),
    label: {
      type: [String, Array],
      default: 'ET.group.PL',
    },
    icon: {
      type: String,
      default: '$employee_group',
    },
    outlined: mergeProps(SAutocomplete.options.props.outlined, {
      default: true,
    }),
    autoSelectFirst: mergeProps(SAutocomplete.options.props.autoSelectFirst, {
      default: true,
    }),
  },
})
