
export default {
  name: 'v-icon-label',
  props: {
    icon: String,
    iconClass: {
      type: [String, Array, Object],
      default: 'mt-n1',
    },
    text: String,
  },
}
