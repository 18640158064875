import {defaultData} from "./state";

export default {
  SET_DATA(state, data) {
    state.data = data
  },
  SET_CONFIG(state, data) {
    state.config = data || {}
  },
  SET_THEME_MODE(state, data) {
    state.theme.mode = data
  },
  SET_THEME_CONFIG(state, {day_start, day_end}) {
    Object.assign(state.theme, {day_start, day_end})
  },
  SET_NOTEBOOK(state, text) {
    state.notebook = text || ''
  },
  SET_TASK_STAGES_REVERSE(state, bool) {
    state.task_stages_reverse = !!bool
  },
  SET_TASK_STAGES_WORKING_ONLY(state, bool) {
    state.task_stages_working_only = !!bool
  },
  RESET(state) {
    state = Object.assign(state, defaultData())
  }
}
