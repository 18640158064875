export const ParticipantSubjectIcons = {
  employee: '$employee',
  group: '$employee_group',
  company: '$company',
}

export const ParticipantTypes = [
  {
    title: 'ET.task_stage_participant.type.responsible',
    value: 'responsible',
  },
  {
    title: 'ET.task_stage_participant.type.doing',
    value: 'doing',
  },
  {
    title: 'ET.task_stage_participant.type.finished',
    value: 'finished',
  },
]

export function getParticipantType (participant) {
  return participant.employee && 'employee'
    || participant.group && 'group'
    || participant.company && 'company'
    || null
}

export function getParticipantIcon (participant) {
  return ParticipantSubjectIcons[getParticipantType(participant) || ''] || null
}
