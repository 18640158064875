import Vue from 'vue'

export default function (allItems) {
  return Vue.extend({
    props: {
      nullItemText: String,
      nullItemValue: {
        type: String,
        default: 'null',
      },
    },
    computed: {
      allItems () {
        const items = allItems.call(this)
        if (
          this.nullItemText
          && typeof this.itemText === 'string'
          && typeof this.itemValue === 'string'
        ) {
          items.unshift({
            [this.itemText]: this.nullItemText,
            [this.itemValue]: this.nullItemValue,
          })
        }
        return items
      },
    },
  })
}
