
export default {
  name: 's-reload-button',
  inheritAttrs: false,
  props: {
    btnClass: {},
    btnStyle: {},
  },
  data: () => ({
    loading: false,
    reloading: false,
    disabled: false,
    keyReloading: false,
  }),
  mounted () {
    document.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeyDown)
  },
  methods: {
    onKeyDown (e) {
      if (e.keyCode === 82 && e.altKey) {
        e.preventDefault()
        this.reload()
      }
    },
    async reload () {
      if (this.disabled || this._inactive) return
      this.disabled = this.reloading = true
      const key = this.keyReloading = {}
      const promises = [
        (new Promise(resolve => setTimeout(resolve, 1500))).then(() => {
          this.disabled = false
        }),
      ]
      this.$root.$emit('reloadData', (promise) => {
        promises.push(promise)
      })
      this.$route.matched.forEach(m => {
        for (const key in m.instances) {
          if (!m.instances[key]._inactive && m.instances[key].$fetch) {
            promises.push(m.instances[key].$fetch())
          }
        }
      })
      await Promise.allSettled(promises)
      if (key === this.keyReloading) {
        this.reloading = false
      }
    }
  },
  watch: {
    '$nuxt.$loading.loading': {
      handler (b) {
        this.loading = b
      },
      immediate: true
    }
  }
}
