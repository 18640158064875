
  import {debounce} from 'lodash'
  import {getStageData} from "@/utils/smarteh/tasks/stages";
  export default {
        data: () => ({
          loading: false,
          result: null,
          search: '',
          error: null,
          pending: null,
          selected: null,
        }),
    computed: {
      items() {
        const list = []
        if(this.result) {
          if(this.result.tasks && this.result.tasks.length) {
            list.push({
              header: this.result.tasks.length + ' ' +this.$num2str(this.result.tasks.length, ['заявка', 'заявки', 'заявок'])
            })
            list.push(...this.result.tasks.map(t => ({
              value: `task:${t.task_id}`,
              text: `Заявка №${t.task_id}`,
              task: t,
            })))
          }
          if(this.result.materials && this.result.materials.length) {
            list.push({
              header: this.result.materials.length + ' ' + this.$num2str(this.result.materials.length, ['матеріал', 'матеріала', 'матеріалов'])
            })
            list.push(...this.result.materials.map(m => ({
              value: `material:${m.material_id}`,
              text: `Матеріал №${m.material_id}`,
              material: m,
            })))
          }
        }
        return list
      },
    },
    methods: {
      debounceFetch: debounce(function () {
        this.fetch()
      }, 500),
      async fetch() {
        if(!this.search) return;
        try {
          this.loading = true
          this.error = null
          this.pending = await this.$axios.getWithCancel('search', {
            params: {
              id: this.search,
            },
          })
          this.result = await this.pending
          this.$fire.analytics.logEvent('search', {
            id: this.search,
          })
          this.pending = null
        } catch (e) {
          if(!this.$axios.isCancel(e)) {
            this.error = e
          }
        } finally {
          this.$nextTick(() => {
            if (!this.pending) {
              this.loading = false
            }
          })
        }
      },
      getTaskStageConfig(task) {
        return task.stage && getStageData(task.stage.type)
      },
      getItemLink(item) {
        if(item.task) {
          return `/tasks/${item.task.task_id}`
        } else if(item.material) {
          return `/materials/${item.material.material_id}`
        }
      },
    },
    watch: {
      search(text) {
        if (this.pending) this.pending.cancel()
        this.pending = null
        this.result = null
        if (text) {
          text = `${text}`
          const newText = text.replace(/[^\d]+/g, '')
          if (newText !== text)
            this.$nextTick(() => {
              this.search = newText
            })
          this.debounceFetch()
        }
      },
      selected(item) {
        if(item) {
          const link = this.getItemLink(item)
          if(link) {
            this.$router.push(link)
          }
          this.$nextTick(() => {
            this.selected = null
          })
        }
      }
    }
  }
