export default function ({store, redirect, route}) {
  if(!store.getters['auth/hasToken'] && route.name !== 'login') {
    redirect({
      name: 'login',
      query: {
        back: route.fullPath
      }
    })
  } else if (store.getters['auth/hasToken'] && route.name === 'login') {
    redirect('/')
  }
}
