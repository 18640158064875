
export default {
  name: 'loading',
  data: () => ({
    show: false,
    error: false,
    loading: false,
    success: false,
    manual: false,
    timeout: null,
  }),
  methods: {
    start (manual = false) {
      this.show = true
      this.loading = true
      this.error = false
      this.success = false
      this.manual = manual
      this.clearTimeout()
      return this
    },
    fail() {
      this.error = true
      return this
    },
    finish() {
      this.loading = false
      if (!this.error) this.success = true
      this.setTimeoutHide(this.error ? 1000 : 500)
      this.manual = false
      return this
    },
    setTimeoutHide (time) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.show = false
      }, time)
    },
    clearTimeout () {
      if (!this.timeout) return
      clearTimeout(this.timeout)
      this.timeout = null
    },
  }
}
