
import { VCheckbox } from 'vuetify/lib/components'

export default VCheckbox.extend({
  name: 's-boolean',
  props: {
    indeterminateIcon: {
      type: String,
      default: '$checkboxOff',
    },
    offIcon: {
      type: String,
      default: '$checkboxIndeterminate',
    },
  },
  computed: {
    computedIcon () {
      if (typeof this.internalValue !== 'boolean') {
        return this.indeterminateIcon
      } else if (this.isActive) {
        return this.onIcon
      } else {
        return this.offIcon
      }
    },
  },
  methods: {
    onChange () {
      if (!this.isInteractive) return
      let input = this.internalValue

      if (typeof input === 'boolean') {
        if (input) {
          input = false
        } else {
          input = null
        }
      } else {
        input = true
      }

      this.validate(true, input)
      this.internalValue = input
      this.hasColor = input
    },
  },
})
