import Vue from 'vue'
import VArchivedIcon from '@/components/s/archived-icon'
import { VListItemContent, VListItemTitle } from 'vuetify/lib/components'
import { getPropertyFromItem } from 'vuetify/lib/util/helpers'

export default function (listData) {
  return Vue.extend({
    props: {
      itemArchived: {
        type: [String, Function],
        default: 'archived',
      },
    },
    computed: {
      listData () {
        const data = listData.call(this)
        if (!data.scopedSlots.item) {
          data.scopedSlots.item =
            ({ item }) => this.$createElement(
              VListItemContent,
              [
                this.$createElement(
                  VListItemTitle,
                  {
                    class: {
                      'font-italic font-weight-regular': getPropertyFromItem(
                        item, this.itemValue) === this.nullItemValue,
                    },
                  },
                  [
                    this.getText(item),
                    getPropertyFromItem(item, this.itemArchived)
                      ? this.$createElement(VArchivedIcon, {
                        props: {
                          xSmall: true,
                          iconClass: 'mt-n1 ml-1',
                        },
                      })
                      : null,
                  ],
                ),
              ],
            )
        }
        return data
      },
    },
  })
}
