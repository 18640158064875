import Vue from "vue";

export const state = Vue.observable({
  already: false,
  installed: false,
  prompt: null
});

export const check = () => {
  state.installed = navigator && navigator.standalone || window && window.matchMedia && window.matchMedia('(display-mode: standalone)').matches || false
}

export const install = async () => {
  if(!state.already) return;
  state.already = false

  state.prompt.prompt()
  const result = await state.prompt.userChoice

  return result.outcome === 'accepted'
}

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  state.prompt = e;
  state.already = true
});

window.addEventListener('appinstalled', (evt) => {
  state.installed = true
});

check()

export default {
  state,
  install
}
