import { save } from '@/utils/files/blob'
import { getFileNameFromDisposition } from '@/utils/http'

export default function ({ $axios, redirect, store }, inject) {
  if (store.state.auth.token) $axios.setToken(store.state.auth.token, 'Bearer')

  for (const method of ['get', 'head']) {
    $axios[`${method}WithCancel`] = function (url, config = {}) {
      const controller = new AbortController()
      const promise = $axios[method].call($axios, url, {
        signal: controller.signal,
        ...config,
      })
      promise.cancel = controller.abort.bind(controller)
      promise.controller = controller
      promise.promise = promise
      promise.signal = controller.signal
      return promise
    }
  }
  for (const method of ['post', 'put', 'patch']) {
    $axios[`${method}WithCancel`] = function (url, data, config = {}) {
      const controller = new AbortController()
      const promise = $axios[method].call($axios, url, data, {
        signal: controller.signal,
        ...config,
      })
      return { promise, cancel: controller.abort, controller }
    }
  }

  $axios.onError(error => {
    if (error.response) {
      error.code = parseInt(error.response && error.response.status)
      error.message = `[${error.response.status}] `
      if (error.code === 401) {
        if (store.getters['auth/hasToken'])
          store.dispatch('auth/logout').then(() => {
            redirect('/login')
          })
      }
      if (error.response.data.message) {
        error.message += error.response.data.message
      } else {
        error.message += ({
          400: 'Помилка на стороні клієнту.\nЗверніться до адміністратору.\n' +
            JSON.stringify(error.response.data),
          401: 'Потрібна авторизація',
          403: 'Немає прав доступу',
          404: 'Не знайдено',
          409: 'Конфлікт даних',
          422: 'Помилка на стороні клієнту.\nЗверніться до адміністратору.\n' +
            JSON.stringify(error.response.data),
          500: 'Помилка зі сторони серверу',
          503: 'Сервіс тимчасово не доступний',
        })[error.code] || error.message
      }
      if (error.response.data.error) {
        error.message += '\n' + error.response.data.error
      }
    } else if (error.message === 'Network Error') {
      error.message = 'Проблеми зі зв\'язком'
    }
  })

  $axios.interceptors.response.use(response => response.data)
  inject('download', (url, config = {}, fallback_filename) => {
    const axios = $axios.create({
      baseURL: $axios.baseURL,
    })
    if (store.state.auth.token) $axios.setToken(store.state.auth.token,
      'Bearer')
    return axios.get(url, {
      responseType: 'blob',
      ...config,
    }).then((response) => {
      let filename = null
      if (response.headers['content-disposition']) {
        filename = getFileNameFromDisposition(
          response.headers['content-disposition'])
      }
      save(response.data, filename || fallback_filename)
    })
  })
}
