import { state as fetchState } from '@/utils/store/fetch'
import { state as metaState } from '@/utils/store/meta'
import { state as paginationState } from '@/utils/store/pagination'
import { state as filterState } from '@/utils/store/filter'
import { state as filterOptionsState } from '@/utils/store/filterOptions'

export const defaultData = () => {
  return {
    list: null,

    ...fetchState(),
    ...metaState(),
    ...paginationState(),
    ...filterState(),
    ...filterOptionsState(),

    show_filter: null,
  }
}

export default () => defaultData()
