import { filterParametersFilter } from '@/utils/smarteh/tasks/filter'

export const state = () => ({
  filter_options: {},
  filter_loading: {},
  filter_error: {},
})

export const mutations = () => ({
  SET_FILTER_ITEM (state, items = {}) {
    state.filter = filterParametersFilter({ ...state.filter, ...items })
  },
  SET_FILTER_OPTIONS (state, options) {
    state.filter_options = Object.assign({}, state.filter_options, options)
  },
  SET_FILTER_LOADING (state, loading) {
    state.filter_loading = Object.assign({}, state.filter_loading,
      loading || {})
  },
  SET_FILTER_ERROR (state, error) {
    state.filter_error = Object.assign({}, state.filter_error, error || {})
  },
})
