export const PER_PAGE = 20

export const paginationToQuery = ({ per_page, page }) => {
  const params = {
    per_page,
  }
  if (page > 1) {
    params.page = page
  }
  return params
}

export const queryToPagination = (
  { per_page, page }, default_per_page = PER_PAGE) => ({
  page: +page || 1,
  per_page: +per_page || default_per_page,
})
