import { getLabel } from '@/utils/label'
import { VIcon, VLabel } from 'vuetify/lib/components'
import Vue from 'vue'

export default Vue.extend({
  props: {
    icon: String,
  },
  methods: {
    genLabel () {
      if (!this.showLabel) return null
      const data = {
        props: {
          absolute: true,
          color: this.validationState,
          dark: this.dark,
          disabled: this.isDisabled,
          focused: !this.isSingle && (this.isFocused || !!this.validationState),
          for: this.computedId,
          left: this.labelPosition.left,
          light: this.light,
          right: this.labelPosition.right,
          value: this.labelValue,
        },
      }

      return this.$createElement(
        VLabel,
        data,
        this.$slots.label
        || [
          this.icon && this.$createElement(VIcon, {
            props: {
              small: true,
              color: 'inherit',
            },
            class: 'mt-n1 mr-1',
          }, this.icon),
          getLabel.call(this, this.label, this.multiple ? this.computedCounterValue : this.value ? 1 : 0),
        ],
      )
    },
  },
})
