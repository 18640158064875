export default function({app, $vuetify, store, $dayjs}) {
  let clock = null;

  const autoCheckOn = () => {
    if (clock === null) clock = setInterval(app.$updateTheme, 60*1000);
  }
  const autoCheckOff = () => {
    if(clock !== null) clearInterval(clock);
    clock = null
  }

  const isTimeNight = () => {
    const {day_start, day_end} = store.state.profile.theme
    let value = false
    try {
      const time = $dayjs().format('HH:mm')
      value = !(day_start <= time && time < day_end)
    } catch (e) {}
    return value;
  }

  const isSystemDark = () => {
    let value = false
    try {
      value = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    } catch (e) {}
    return value
  }

  app.$updateTheme = () => {
    const {mode} = store.state.profile.theme;
    switch (mode) {
      case "auto":
        $vuetify.theme.dark = isSystemDark() || isTimeNight()
        break;
      case "system":
        $vuetify.theme.dark = isSystemDark()
        break;
      case "time":
        $vuetify.theme.dark = isTimeNight()
        break;
      case "dark":
        $vuetify.theme.dark = true
        break;
      case "light":
        $vuetify.theme.dark = false
        break;
    }

    if (['auto', 'time', 'system'].includes(mode)) {
      autoCheckOn();
    } else {
      autoCheckOff();
    }
  }

  store.watch(
    state => state.profile.theme,
    () => {
      app.$updateTheme();
    }, {
      deep: true,
      immediate: true
    })
}
