import Vue from 'vue'
import {DateTimeMask, FORMATS} from '@/utils/format/time'
import {num2str} from "@/utils/format/string";
import {timeoutPromise} from "@/utils/timeout";
import {isMobile} from "@/utils/device/mobile";
import {getParticipantIcon, getParticipantType} from "@/utils/smarteh/tasks/participants";
import {multiNextTick} from "@/utils/vue";
import {getFullName} from "@/utils/smarteh/employees/format";
import {multiSort, sortByString, sortByParam} from "@/utils/sort";
import {PhoneMask} from "@/utils/format/phone";
import {filterItem as objectFilterItem} from "@/utils/smarteh/objects/filter";

Vue.prototype.$feedback = {}

Vue.prototype.$loading = {
    start() {
        if (!$nuxt.$loading.start) {
            Vue.nextTick(() => {
                $nuxt.$loading.start()
            })
        } else {
      $nuxt.$loading.start()
    }
  },
  finish() {
    if (!$nuxt.$loading.finish) {
      Vue.nextTick(() => {
        $nuxt.$loading.finish()
      })
    } else {
      $nuxt.$loading.finish()
    }
  },
  fail() {
    if (!$nuxt.$loading.fail) {
      Vue.nextTick(() => {
        $nuxt.$loading.fail()
      })
    } else {
      $nuxt.$loading.fail()
    }
  }
}

Vue.prototype.$mask = {
    phone: PhoneMask,
    datetime: DateTimeMask,
}

Vue.prototype.$filter = {
    objects: objectFilterItem,
}

Vue.prototype.$participant_type = getParticipantType
Vue.prototype.$participant_icon = getParticipantIcon
Vue.prototype.$nextTickMulti = multiNextTick

export default ({app: {$dayjs, $format, $fire}, store}, inject) => {
    inject('format', FORMATS)
    inject('console', console)
    inject('num2str', num2str)
    inject('timeout', timeoutPromise)
  inject('isMobile', isMobile())
    const employee_title = (employee) => {
        return getFullName(employee, store.state.profile.config.name_format === 'name_surname')
    }
  inject('employee_title', employee_title)

  Vue.prototype.$participant_title = participant =>
    participant.employee && employee_title(participant.employee)
    || participant.group && participant.group.name
    || participant.company && participant.company.name
    || 'NULL'

  inject('sort', {
      employees: sortByString(employee_title),
      objects: sortByString(v => `${v.code || v.name}`),
      companies: sortByParam('name'),
      groups: sortByParam('name'),
      taskCategories: sortByParam('name'),
      byParam: sortByParam,
      byString: sortByString,
      groupSort: multiSort,
  })
}
