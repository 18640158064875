export const status = [
  {
    name: 'ET.report.status.WAITING',
    color: 'blue accent-4',
    value: 'WAITING',
  },
  {
    name: 'ET.report.status.PROCESSING',
    color: 'yellow accent-4',
    value: 'PROCESSING',
  },
  {
    name: 'ET.report.status.DONE',
    color: 'green accent-4',
    value: 'DONE',
  },
  {
    name: 'ET.report.status.FAILED',
    color: 'red accent-4',
    value: 'FAILED',
  },
  {
    name: 'ET.report.status.CANCELED',
    color: 'grey accent-4',
    value: 'CANCELED',
  },
]

export const statusByValue = status.reduce((o, v) => (o[v.value] = v, o), {})

export const getStatusData = (value) => statusByValue[value] || null
