
import { factory } from 'vuetify/lib/mixins/toggleable'

export default {
  name: 's-modal',
  mixins: [factory('visible', 'visible')],
  props: {
    visible: Boolean,
    title: String,
    titleIcon: String,
    titleClass: [String, Array, Object],
    subtitle: String,
    subtitleClass: [String, Array, Object],
    cardTextClass: [String, Array, Object],
    fullscreen: {
      type: [Boolean, String],
      default: 'mobile',
    },
    maxWidth: {
      type: [String, Number],
      default: '700px',
    },
    loading: Boolean,
    persistent: Boolean,
    error: {
      required: false,
    },
    fatalError: {},
    closeDisabled: {
      type: Boolean,
      default: false,
    },
    actionsClass: {
      type: [String, Array, Object],
      default: 'justify-center',
    },
  },
  computed: {
    isFullscreen() {
      if (typeof this.fullscreen === 'boolean') {
        return this.fullscreen
      }
      return this.$vuetify.breakpoint[this.fullscreen]
    },
  },
  methods: {
    open() {
      this.isActive = true
    },
    close() {
      this.isActive = false
    },
    toggle() {
      this.isActive = !this.isActive
    },
    onRouteLeave () {
      if (this.persistent) {
        this.$refs.dialog && this.$refs.dialog.animateClick()
        return true
      } else {
        this.close()
        return true
      }
    }
  },
  watch: {
    isActive: {
      handler (val) {
        if (val) {
          this.$blockRouteLeave(this, this.onRouteLeave)
        } else {
          this.$unBlockRouteLeave(this)
        }
      }
    }
  }
}
