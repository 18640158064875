
import SAutocomplete from '@/components/s/autocomplete'
import { mergeProps } from '@/utils/mergeData'

export default {
  name: 'employee-autocomplete',
  extends: SAutocomplete,
  props: {
    itemText: mergeProps(
      SAutocomplete.options.props.itemText,
      {
        default () {
          return (item) => this.$employee_title(item)
        },
      },
    ),
    itemValue: mergeProps(SAutocomplete.options.props.itemValue, {
      default: 'employee_id',
    }),
    label: {
      type: [String, Array],
      default: 'ET.employee.PL',
    },
    icon: {
      type: String,
      default: '$employee',
    },
    outlined: mergeProps(SAutocomplete.options.props.outlined, {
      default: true,
    }),
    autoSelectFirst: mergeProps(SAutocomplete.options.props.autoSelectFirst, {
      default: true,
    }),
  },
}
