import {
  filterParametersFilter as baseFilterParametersFilter,
  filterToQuery as baseFilterToQuery,
  filterFromQuery as baseFilterFromQuery, getDefaultFilterByMap,
} from '@/utils/smarteh/filter'

const TasksFilterMap = {
  search: String,
  search_fields: Array,
  task_id: Number,
  prengi_id: Number,
  serviteh_id: Number,
  client_id: Number,
  object_id: Number,
  contractor_id: Number,
  category_id: Number,
  stage_id: Number,
  description: String,
  applicant_id: Number,
  urgency: Array,
  submitted_at: Date,
  deadline_at: Date,
  finished_at: Date,
  activity_code_id: Number,
  reason_id: Number,
  bill_recipient_id: Number,
  updated_at: Date,
  created_at: Date,
  favorite: Boolean,
  overdue: Boolean,
  stage_overdue: Boolean,
  stages_finished_by_id: Number,
}
for (const prefix of ['stage', 'stages']) {
  TasksFilterMap[`${prefix}_responsible_id`] = Number
  TasksFilterMap[`${prefix}_subcontractor_id`] = Number
  TasksFilterMap[`${prefix}_type`] = Array
  TasksFilterMap[`${prefix}_status`] = Array
  TasksFilterMap[`${prefix}_deadline_at`] = Date
  TasksFilterMap[`${prefix}_created_at`] = Date
  TasksFilterMap[`${prefix}_started_at`] = Date
  TasksFilterMap[`${prefix}_finished_at`] = Date
  TasksFilterMap[`${prefix}_created_by_id`] = Number
  TasksFilterMap[`${prefix}_participants_employee_id`] = Number
  TasksFilterMap[`${prefix}_participants_group_id`] = Number
  TasksFilterMap[`${prefix}_participants_company_id`] = Number
  TasksFilterMap[`${prefix}_participants_group_employee_id`] = Number
  TasksFilterMap[`${prefix}_participants_employee_group_id`] = Number
  TasksFilterMap[`${prefix}_type`] = Array
}

export { TasksFilterMap }

export function getDefaultFilter () {
  return getDefaultFilterByMap(TasksFilterMap)
}

export function filterParametersFilter (filter) {
  return baseFilterParametersFilter(filter, TasksFilterMap)
}

export function filterToQuery (filter, local = false) {
  filter = { ...filter }
  if (!filter.search) {
    delete filter.search_fields
  }
  return baseFilterToQuery(filter, TasksFilterMap, local)
}

export function filterFromQuery (filter) {
  return baseFilterFromQuery(filter, TasksFilterMap)
}
