import { FIELDS } from '@/store/tasks/actions'
import { filterToQuery } from '@/utils/smarteh/tasks/filter'

export default ({ store, $socket, $axios }) => {
  let socket = null

  const fetchTask = (task_id) => {
    const params = {
      ...filterToQuery(store.state.tasks.filter),
      task_id,
      fields: FIELDS,
    }
    return $axios.get('tasks', {
      params,
    })
  }

  const onTaskChange = (data) => {
    if (typeof data !== 'object' && !data.task_id) return
    const { task_id } = data
    if (
      data.timestamp
      && store.state.tasks.timestamp
      && data.timestamp < store.state.tasks.timestamp
    ) return
    const local_task = store.state.tasks.list.find(t => t.task_id === task_id)
    if (!local_task) return

    if (data.event === 'TaskCommentDeleted') {
      store.commit('tasks/UPDATE_TASK', {
        ...local_task,
        comments: local_task.comments?.filter(
          c => c.comment_id !== data.comment_id),
      })
      return
    }

    fetchTask(data.task_id).then(({ data, timestamp }) => {
      if (timestamp <= store.state.tasks.timestamp) return
      if (!data.length) {
        store.commit(
          'tasks/SET_TASKS',
          store.state.tasks.list.filter(t => t.task_id !== task_id),
        )
      } else {
        store.commit('tasks/UPDATE_TASK', data[0])
      }
      if (store.state.tasks.timestamp < timestamp) {
        store.commit('tasks/SET_TIMESTAMP', timestamp)
      }
    })
  }

  const run = () => {
    if (socket) return
    socket = $socket('/tasks')
    socket.on('task:stage:change', onTaskChange)
    socket.on('task:comment:change', onTaskChange)
  }
  const stop = () => {
    if (!socket) return
    socket.off('task:stage:change', onTaskChange)
    socket.off('task:comment:change', onTaskChange)
    socket.close()
    socket = null
  }

  store.watch(
    (state) => (state.tasks.list || []).length,
    length => {
      if (length > 0) {
        run()
      } else {
        stop()
      }
    },
    {
      immediate: true,
    },
  )
}
