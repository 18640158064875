
export default {
  name: 's-help-icon',
  inheritAttrs: false,
  props: {
    size: {
      type: String,
    },
    text: String,
    color: {
      type: String,
      default: 'info',
    },
    btnClass: [String, Array, Object],
    btnStyle: [String, Array, Object],
  },
}
