export default {
  SET_BREADCRUMBS(state, breadcrumbs) {
    state.breadcrumbs = Array.isArray(breadcrumbs)? breadcrumbs: []
  },
  SHOW_BREADCRUMBS(state, bool) {
    state.show_breadcrumbs = !!bool
  },
  SET_NAV(state, bool) {
    state.nav = !!bool
  },
  TOGGLE_NAV(state) {
    state.nav = !state.nav
  },
  SHOW_PROFILE(state, boolean) {
    state.show_profile = !!boolean
  },
  SET_CONFIG(state, config) {
    Object.assign(state.config, config)
  },


  SHOW_EMPLOYEE_DETAILS(state, employee) {
    state.show_employee_details = employee || null
  },
  SHOW_PARTICIPANT_DETAILS(state, participant) {
    state.show_participant_details = participant || null
  }
}
